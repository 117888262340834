import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import axios from "axios";
import MaterialTable from "material-table";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  Box,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";

import ModalAddPLU from "./Assets/ModalAddPLU";
import { dateFormat } from "../utils/dateFromat";

export default function PLUList() {
  const [loading, setLoading] = useState(true);
  const [dept, setDept] = useState();
  const [deptDatas, setDeptDatas] = useState();
  const [datas, setDatas] = useState();
  const [spacing] = useState(2);
  const [columns] = useState([
    { title: "Département", field: "department", type: "numeric" },
    { title: "État", field: "state" },
    { title: "Ville", field: "name_real" },
    { title: "INSEE", field: "insee", type: "numeric" },
    { title: "PLU", field: "is_plu", type: "boolean", filtering: false },
    { title: "PLUI", field: "is_plui", type: "boolean", filtering: false },
    {
      title: "Geoportail",
      field: "is_geoportail",
      type: "boolean",
      filtering: false,
    },
    { title: "Origine", field: "origin" },
    {
      title: "Date document",
      field: "date_document",
      type: "date",
      emptyValue: "Date non renseigné",
      render: (rowData) => (
        <Moment format="YYYY-MM-DD">{rowData.date_document}</Moment>
      ),
    },
    {
      title: "Nouvelle date document",
      field: "new_date_document",
      type: "date",
      emptyValue: "Date non renseigné",
      render: (rowData) => (
        <Moment format="YYYY-MM-DD">{rowData.new_date_document}</Moment>
      ),
    },
    {
      title: "Date de vérification",
      field: "date_check_maj",
      type: "date",
      emptyValue: "Date non renseigné",
      render: (rowData) => (
        <Moment format="YYYY-MM-DD">{rowData.date_check_maj}</Moment>
      ),
    },
    {
      title: "Lien à jour",
      field: "update_link",
    },
    { title: "Commentaire", field: "comment" },
  ]);

  const useStyles = makeStyles((theme) => ({
    button: {
      display: "block",
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(5),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }));

  const classes = useStyles();

  const handleSubmit = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/pluDateIntegration`,
      {
        params: { dept: dept },
      }
    );
    setDatas(res.data);
  };

  useEffect(() => {
    const getDeptDatas = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/geoportailDatas/departments`
      );
      setDeptDatas(res.data);
      setLoading(false);
    };
    getDeptDatas();
  }, []);

  const handleEdit = async (rowData) => {
    rowData.date_document = dateFormat(rowData.date_document);
    rowData.new_date_document = dateFormat(rowData.new_date_document);
    rowData.date_check_maj = dateFormat(rowData.date_check_maj);

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/pluDateIntegration/${rowData.id}`,
        rowData
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" m={1} p={1}>
          <CircularProgress />
        </Box>
      ) : (
        <Box m={5}>
          <Grid item xs={12}>
            <Grid container justify="center">
              <FormControl className={classes.formControl}>
                <InputLabel id="département-label">Département</InputLabel>
                <Select
                  labelId="sépartement-label"
                  id="département"
                  value={dept ? dept : ""}
                  onChange={(event) => {
                    setDept(event.target.value);
                  }}
                >
                  {deptDatas.map((data) => {
                    return (
                      <MenuItem key={data.substr} value={data.substr}>
                        {data.substr}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <Button
                className={classes.button}
                onClick={handleSubmit}
                color="primary"
                variant="contained"
              >
                chercher
              </Button>
            </Grid>
            <Grid container justify="center">
              <Box m={5}>
                <ModalAddPLU
                  deptDatas={deptDatas}
                  setPluDatas={setDatas}
                  pluDatas={datas}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      {datas ? (
        <MaterialTable
          title="PLU"
          columns={columns}
          data={datas}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                handleEdit(newData);
                const dataUpdate = [...datas];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setDatas([...dataUpdate]);
                resolve();
              }),
          }}
          options={{
            addRowPosition: "first",
            exportButton: true,
            filtering: true,
            pageSize: 50,
            pageSizeOptions: [50, 100, 200],
          }}
        />
      ) : (
        <Grid container justify="center" spacing={spacing}>
          <p>Recherche un Département</p>
        </Grid>
      )}
    </>
  );
}
