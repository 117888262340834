import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import axios from "axios";
import MaterialTable from "material-table";
import { CircularProgress, Box } from "@material-ui/core";

export default function GeoportailPushs() {
  const [loading, setLoading] = useState(true);
  const [rows] = useState(7);
  const [datas, setDatas] = useState([]);
  const [columns] = useState([
    {
      title: "Date",
      field: "date",
      render: (rowData) => <Moment format="YYYY/MM/DD">{rowData.date}</Moment>,
    },
    { title: "Items", field: "cnt_items" },
  ]);

  useEffect(() => {
    const getDatas = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/geoportailPushes`,
        {
          params: { limit: rows },
        }
      );
      setDatas(res.data);
      setLoading(false);
    };
    getDatas();
  }, [rows]);

  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" m={1} p={1}>
          <CircularProgress />
        </Box>
      ) : (
        <MaterialTable
          title="Geoportail Pushs"
          columns={columns}
          data={datas}
          options={{
            exportButton: true,
            paging: false,
          }}
        />
      )}
    </>
  );
}
