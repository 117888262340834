import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import axios from "axios";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  CircularProgress,
  Box,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";

export default function NotreTerritoire() {
  const [loading, setLoading] = useState(true);
  const [dept, setDept] = useState();
  const [deptDatas, setDeptDatas] = useState();
  const [datas, setDatas] = useState();
  const [spacing] = useState(2);
  const [columns] = useState([
    {
      title: "Traité",
      field: "treatment",
      type: "boolean",
      filtering: false,
      render: (rowData) => (
        <>
          <Checkbox
            checked={rowData.treatment}
            onChange={(event) => handleChangeIsTreatment(event, rowData)}
          />
        </>
      ),
    },
    { title: "ID", field: "id" },
    { title: "Département", field: "departement" },
    {
      title: "Date get",
      field: "date_get",
      render: (rowData) => (
        <Moment format="YYYY/MM/DD">{rowData.date_get}</Moment>
      ),
    },
    {
      title: "Date start",
      field: "date_start",
      render: (rowData) => (
        <Moment format="YYYY/MM/DD">{rowData.date_start}</Moment>
      ),
    },
    {
      title: "Date end",
      field: "date_end",
      render: (rowData) => (
        <Moment format="YYYY/MM/DD">{rowData.date_end}</Moment>
      ),
    },
    { title: "Titre", field: "title" },
    { title: "Propriétaire", field: "owner" },
    { title: "Villes", field: "cities" },
    {
      title: "URL",
      field: "ad_id",
      render: (rowData) => {
        const urlAd = `https://www.notre-territoire.com/enquete/${rowData.ad_id}`;
        return (
          <a href={urlAd} target="_blank" rel="noopener noreferrer">
            Consulter l’étude
          </a>
        );
      },
    },
  ]);

  const useStyles = makeStyles((theme) => ({
    button: {
      display: "block",
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(5),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }));

  const classes = useStyles();

  const fetchByDept = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/notreTerritoireDatas`,
      {
        params: { dept: dept },
      }
    );
    setDatas(res.data);
  };

  const fetchAll = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/notreTerritoireDatas`
    );
    setDatas(res.data);
  };

  useEffect(() => {
    const getDeptDatas = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/geoportailDatas/departments`
      );
      setDeptDatas(res.data);
      setLoading(false);
    };
    getDeptDatas();
  }, []);

  const handleChangeIsTreatment = async (event, rowData) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/notreTerritoireDatas/${rowData.id}/treatment`,
        {
          treatment: !rowData.treatment,
        }
      );
      setDatas((prevState) => {
        const newArrDatas = [...prevState];
        const newDatasTreatment = newArrDatas.map((row) => {
          if (row.id === rowData.id) {
            row.treatment = !rowData.treatment;
          }
          return row;
        });
        return newDatasTreatment;
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" m={1} p={1}>
          <CircularProgress />
        </Box>
      ) : (
        <Box m={5}>
          <Grid item xs={12} spacing={2}>
            <Grid container justify="center" spacing={2}>
              <FormControl className={classes.formControl}>
                <InputLabel id="département-label">Département</InputLabel>
                <Select
                  labelId="sépartement-label"
                  id="département"
                  value={dept}
                  onChange={(event) => {
                    setDept(event.target.value);
                  }}
                >
                  {deptDatas.map((data) => {
                    return (
                      <MenuItem value={data.substr}>{data.substr}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Button
                className={classes.button}
                onClick={fetchByDept}
                color="primary"
                variant="contained"
              >
                chercher par département
              </Button>
              <Button
                className={classes.button}
                onClick={fetchAll}
                color="primary"
                variant="contained"
              >
                chercher tous les départements
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      {datas ? (
        <MaterialTable
          title="Notre Territoire Datas"
          columns={columns}
          data={datas}
          options={{
            exportButton: true,
            filtering: true,
            pageSize: 50,
            pageSizeOptions: [50, 100, 200],
          }}
        />
      ) : (
        <Grid container justify="center" spacing={spacing}>
          <p>Recherche un Département</p>
        </Grid>
      )}
    </>
  );
}
