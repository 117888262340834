import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import axios from "axios";
import MaterialTable from "material-table";
import { Checkbox, CircularProgress, Box } from "@material-ui/core";

export default function Report() {
  const [loading, setLoading] = useState(true);
  const [datas, setDatas] = useState();
  const [columns] = useState([
    { title: "Id", field: "id" },
    {
      title: "Is checked",
      field: "checked",
      type: "boolean",
      render: (rowData) => (
        <>
          <Checkbox
            checked={rowData.checked}
            onChange={(event) => handleChangeChecked(event, rowData)}
          />
        </>
      ),
    },
    {
      title: "Date integration",
      field: "date_integration",
      render: (rowData) => (
        <Moment format="YYYY-MM-DD">{rowData.date_integration}</Moment>
      ),
    },
    {
      title: "Is valid archive",
      field: "is_valid_archive",
      type: "boolean",
    },
    { title: "INSEE", field: "insee" },
    { title: "City Name", field: "city_name" },
    {
      title: "Date update",
      field: "date_update",
      customFilterAndSearch: (term, rowData) => term < rowData.date_update,
      render: (rowData) => (
        <Moment format="YYYY-MM-DD">{rowData.date_update}</Moment>
      ),
    },
    {
      title: "Has documents folders",
      field: "has_documents_folder",
      type: "boolean",
    },
    {
      title: "Has geo folder",
      field: "has_geo_folder",
      type: "boolean",
    },
    {
      title: "Table is in database",
      field: "table_is_in_database",
      type: "boolean",
    },
    {
      title: "Table test",
      field: "table_test",
      type: "boolean",
    },
    {
      title: "Insert zones validation",
      field: "insert_zones_validation",
      type: "boolean",
    },
    {
      title: "Insert zones details validation",
      field: "insert_zones_details_validation",
      type: "boolean",
    },
    {
      title: "Error tilsets or colorset",
      field: "error_tilesets_or_colorset",
      type: "boolean",
    },
    {
      title: "Documents geographic upload on S3",
      field: "documents_geographic_upload_on_s3",
      type: "boolean",
    },
    {
      title: "Style id is public",
      field: "style_id_is_public",
      type: "boolean",
    },
    {
      title: "Update url in database",
      field: "update_url_in_database",
      type: "boolean",
    },
  ]);

  useEffect(() => {
    const getDatas = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/geoportailRapport`
      );
      setDatas(res.data);
      setLoading(false);
    };
    getDatas();
  }, []);

  const updateDeleted = async (rowData) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/geoportailRapport/${rowData.id}/deleted`,
        {
          deleted: true,
        }
      );
      alert("Tu as supprimé " + rowData.title);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeChecked = async (event, rowData) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/geoportailRapport/${rowData.id}/checked`,
        {
          checked: !rowData.checked,
        }
      );
      setDatas((prevState) => {
        const newArrDatas = [...prevState];
        const newDatasChecked = newArrDatas.map((row) => {
          if (row.id === rowData.id) {
            row.checked = !rowData.checked;
          }
          return row;
        });
        return newDatasChecked;
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" m={1} p={1}>
          <CircularProgress />
        </Box>
      ) : (
        <MaterialTable
          title="Rapports d'intégration Géoportail Bot"
          columns={columns}
          data={datas}
          editable={{
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                updateDeleted(oldData);
                let rows = datas;
                setDatas(rows.filter((data) => data !== oldData));
                resolve();
              }),
          }}
          options={{
            exportButton: true,
            filtering: true,
            pageSize: 50,
            pageSizeOptions: [50, 100, 200],
          }}
        />
      )}
    </>
  );
}
