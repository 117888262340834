import React from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import PropTypes from "prop-types";

function DateInput({ value, handleChange, label, name, helperText }) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        clearable
        name={name}
        label={label}
        value={value}
        format="DD/MM/yyyy"
        onChange={(value) => {
          handleChange(name, value);
        }}
        helperText={helperText}
      />
    </MuiPickersUtilsProvider>
  );
}

DateInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date).isRequired,
    PropTypes.string.isRequired,
  ]),
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
};
export default DateInput;
