import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Modal,
  TextField,
  Checkbox,
  Box,
  Grid,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import DateInput from "./DateInput";
import { dateFormat } from "../../utils/dateFromat";

const top = 50;
const left = 50;
const useStyles = makeStyles((theme) => ({
  paper: {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    borderRadius: 20,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    minWidth: 200,
  },
}));

export default function ModalAddPLU({ deptDatas, setPluDatas, pluDatas }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [successAlert, setSuccessAlert] = React.useState(false);
  const [msgError, setMsgError] = React.useState("");
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  const todayNow = yyyy + "-" + mm + "-" + dd;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleAdd = async (values) => {
    values.date_document = dateFormat(values.date_document);
    values.new_date_document = dateFormat(values.new_date_document);
    values.date_check_maj = dateFormat(values.date_check_maj);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/pluDateIntegration`,
        values
      );
      setSuccessAlert(true);
    } catch (err) {
      console.error(err);
      setSuccessAlert(false);
      setMsgError(err);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.department) {
      errors.department = "Required";
    }
    if (!values.date_document) {
      errors.date_document = "Required";
    }
    if (!values.state) {
      errors.state = "Required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      department: "",
      state: "",
      insee: "",
      is_plu: false,
      is_plui: false,
      is_geoportail: false,
      origin: "",
      date_document: todayNow,
      new_date_document: null,
      date_check_maj: null,
      update_link: "",
      comment: "",
    },
    validate,
    onSubmit: (values) => {
      handleAdd(values);
      setTimeout(() => {
        setOpenAlert(true);
      }, 500);
      if (pluDatas) {
        setPluDatas([...pluDatas, values]);
      }
      handleClose();
    },
  });

  const { values, touched, errors } = formik;

  const body = (
    <div className={classes.paper}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel id="département-label">Département</InputLabel>
              <Select
                labelId="sépartement-label"
                id="department"
                name="department"
                value={values.department}
                onChange={formik.handleChange}
                error={touched.department && Boolean(errors.department)}
                helperText={touched.department && errors.department}
              >
                {deptDatas.map((data) => {
                  return (
                    <MenuItem key={data.substr} value={data.substr}>
                      {data.substr}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              name="state"
              label="État"
              value={values.state}
              onChange={formik.handleChange}
              error={touched.state && Boolean(errors.state)}
              helperText={touched.state && errors.state}
            />
            <TextField
              name="insee"
              label="INSEE"
              value={values.insee}
              onChange={formik.handleChange}
              error={touched.insee && Boolean(errors.insee)}
              helperText={touched.insee && errors.insee}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="is_plu"
                  checked={values.is_plu}
                  onChange={formik.handleChange}
                  error={touched.is_plu && Boolean(errors.is_plu)}
                  helperText={touched.is_plu && errors.is_plu}
                />
              }
              label="PLU"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="is_plui"
                  checked={values.is_plui}
                  onChange={formik.handleChange}
                  error={touched.is_plui && Boolean(errors.is_plui)}
                  helperText={touched.is_plui && errors.is_plui}
                />
              }
              label="PLUi"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="is_geoportail"
                  checked={values.is_geoportail}
                  onChange={formik.handleChange}
                  error={touched.is_geoportail && Boolean(errors.is_geoportail)}
                  helperText={touched.is_geoportail && errors.is_geoportail}
                />
              }
              label="Geoportail"
            />
          </Grid>
          <Grid item xs>
            <TextField
              name="origin"
              label="Origine"
              value={values.origin}
              onChange={formik.handleChange}
              error={touched.origin && Boolean(errors.origin)}
              helperText={touched.origin && errors.origin}
            />
            <DateInput
              name="date_document"
              label="Date document"
              value={values.date_document}
              handleChange={formik.setFieldValue}
              helperText={touched.date_document && errors.date_document}
            />
            <DateInput
              name="new_date_document"
              label="Nouvelle date document"
              value={values.new_date_document}
              handleChange={formik.setFieldValue}
              helperText={touched.new_date_document && errors.new_date_document}
            />
            <DateInput
              name="date_check_maj"
              label="Date de vérification"
              value={values.date_check_maj}
              handleChange={formik.setFieldValue}
              helperText={touched.date_check_maj && errors.date_check_maj}
            />
            <TextField
              name="update_link"
              label="Lien à jour"
              value={values.update_link}
              onChange={formik.handleChange}
              error={touched.update_link && Boolean(errors.update_link)}
              helperText={touched.update_link && errors.update_link}
            />
          </Grid>
          <TextField
            name="comment"
            label="Commentaire"
            value={values.comment}
            onChange={formik.handleChange}
            error={touched.comment && Boolean(errors.comment)}
            helperText={touched.comment && errors.comment}
            multiline
            rows={2}
            fullWidth
          />
        </Grid>
        <Box m={2}>
          <Grid container spacing={2}>
            <Grid item xs>
              <Button
                className={classes.button}
                onClick={formik.handleSubmit}
                color="primary"
                variant="contained"
              >
                Créer un PLU
              </Button>
            </Grid>
            <Grid item xs>
              <Button
                className={classes.button}
                onClick={handleClose}
                color="secondary"
                variant="contained"
              >
                annuler
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );

  return (
    <div>
      <Button
        className={classes.button}
        onClick={handleOpen}
        color="primary"
        variant="contained"
      >
        Créer un PLU
      </Button>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={successAlert ? "success" : "error"}
        >
          {successAlert
            ? "Le PLU a été créer"
            : `Une erreur est survenue: ${msgError}`}
        </Alert>
      </Snackbar>
    </div>
  );
}
