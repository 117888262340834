import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import axios from "axios";
import MaterialTable from "material-table";
import { Button, Checkbox, CircularProgress, Box } from "@material-ui/core";

export default function PLUi() {
  const [loading, setLoading] = useState(true);
  const [dept] = useState("01");
  const [datas, setDatas] = useState();
  const [columns] = useState([
    {
      title: "need_verification",
      field: "need_verification",
      type: "boolean",
      filtering: false,
      render: (rowData) => (
        <>
          <Checkbox
            checked={rowData.need_verification}
            onChange={(event) => handleChangeNeedVerification(event, rowData)}
          />
        </>
      ),
    },
    {
      title: "À traiter",
      field: "toTreat",
      type: "boolean",
      filtering: false,
      render: (rowData) => (
        <>
          <Checkbox
            checked={rowData.to_treat}
            onChange={(event) => handleChangeIsToTreat(event, rowData)}
          />
        </>
      ),
    },
    {
      title: "Traité",
      field: "treatment",
      type: "boolean",
      filtering: false,
      render: (rowData) => (
        <>
          <Checkbox
            checked={rowData.treatment}
            onChange={(event) => handleChangeIsTreatment(event, rowData)}
          />
        </>
      ),
    },
    { title: "ID", field: "id" },
    { title: "Titre", field: "title" },
    { title: "Zone", field: "zone" },
    { title: "Code", field: "code" },
    { title: "Type de données", field: "data_type" },
    {
      title: "Archive",
      field: "archive_link",
      filtering: false,
      render: (rowData) => (
        <Button variant="contained" color="primary" href={rowData.archive_link}>
          Télécharger
        </Button>
      ),
    },
    {
      title: "Dernière modification",
      field: "date_update",
      customFilterAndSearch: (term, rowData) => term < rowData.date_update,
      render: (rowData) => (
        <Moment format="YYYY-MM-DD">{rowData.date_update}</Moment>
      ),
    },
    {
      title: "Publication",
      field: "date_publish",
      customFilterAndSearch: (term, rowData) => term < rowData.date_publish,
      render: (rowData) => (
        <Moment format="YYYY-MM-DD">{rowData.date_publish}</Moment>
      ),
    },
  ]);

  useEffect(() => {
    const getDatas = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/geoportailDatas/PLUi`,
        {
          params: { dept: dept },
        }
      );
      setDatas(res.data);
      setLoading(false);
    };
    getDatas();
  }, [dept]);

  const updateDeleted = async (rowData) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/geoportailDatas/${rowData.id}/deleted`,
        {
          deleted: true,
        }
      );
      alert("Tu as supprimé " + rowData.title);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeIsToTreat = async (event, rowData) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/geoportailDatas/${rowData.id}/toTreat`,
        {
          toTreat: !rowData.to_treat,
        }
      );
      setDatas((prevState) => {
        const newArrDatas = [...prevState];
        const newDatasToTreat = newArrDatas.map((row) => {
          if (row.id === rowData.id) {
            row.to_treat = !rowData.to_treat;
          }
          return row;
        });
        return newDatasToTreat;
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeIsTreatment = async (event, rowData) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/geoportailDatas/${rowData.id}/treatment`,
        {
          treatment: !rowData.treatment,
        }
      );
      setDatas((prevState) => {
        const newArrDatas = [...prevState];
        const newDatasTreatment = newArrDatas.map((row) => {
          if (row.id === rowData.id) {
            row.treatment = !rowData.treatment;
          }
          return row;
        });
        return newDatasTreatment;
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeNeedVerification = async (event, rowData) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/geoportailDatas/${rowData.id}/needVerification`,
        {
          need_verification: !rowData.need_verification,
        }
      );
      setDatas((prevState) => {
        const newArrDatas = [...prevState];
        const newDatasNeedVerification = newArrDatas.map((row) => {
          if (row.id === rowData.id) {
            row.need_verification = !rowData.need_verification;
          }
          return row;
        });
        return newDatasNeedVerification;
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" m={1} p={1}>
          <CircularProgress />
        </Box>
      ) : (
        <MaterialTable
          title="PLUi"
          columns={columns}
          data={datas}
          editable={{
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                updateDeleted(oldData);
                let rows = datas;
                setDatas(rows.filter((data) => data !== oldData));
                resolve();
              }),
          }}
          options={{
            exportButton: true,
            filtering: true,
            pageSize: 50,
            pageSizeOptions: [50, 100, 200],
          }}
        />
      )}
    </>
  );
}
