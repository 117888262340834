import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import axios from "axios";
import MaterialTable from "material-table";
import { Checkbox, CircularProgress, Box } from "@material-ui/core";

export default function CityToCheck() {
  const [loading, setLoading] = useState(true);
  const [datas, setDatas] = useState();
  const [columns] = useState([
    { title: "Id", field: "id" },
    {
      title: "Need to insert",
      field: "need_to_insert",
      type: "boolean",
      filtering: false,
      render: (rowData) => (
        <>
          <Checkbox
            checked={rowData.need_to_insert}
            onChange={(event) => handleChangeChecked(event, rowData)}
          />
        </>
      ),
    },
    {
      title: "INSEE",
      field: "insee",
    },
    { title: "City Name", field: "city_name" },
    {
      title: "Date",
      field: "date",
      render: (rowData) => <Moment format="YYYY-MM-DD">{rowData.date}</Moment>,
    },
    {
      title: "Commentaire",
      field: "commentaire",
    },
    {
      title: "Date de tentative d'intégration",
      field: "integration_date",
      render: (rowData) => (
        <Moment format="YYYY-MM-DD">{rowData.integration_date}</Moment>
      ),
    },
  ]);

  useEffect(() => {
    const getDatas = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/geoportailCitiesToCheck`
      );
      setDatas(res.data);
      setLoading(false);
    };
    getDatas();
  }, []);

  const handleChangeChecked = async (event, rowData) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/geoportailCitiesToCheck/${rowData.id}/needToInsert`,
        {
          needToInsert: !rowData.need_to_insert,
        }
      );
      setDatas((prevState) => {
        const newArrDatas = [...prevState];
        const newDatasChecked = newArrDatas.map((row) => {
          if (row.id === rowData.id) {
            row.need_to_insert = !rowData.need_to_insert;
          }
          return row;
        });
        return newDatasChecked;
      });
    } catch (err) {
      console.error(err);
    }
  };

  const updateDeleted = async (rowData) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/geoportailCitiesToCheck/${rowData.id}/deleted`,
        {
          deleted: true,
        }
      );
      alert("Tu as supprimé " + rowData.city_name);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" m={1} p={1}>
          <CircularProgress />
        </Box>
      ) : (
        <MaterialTable
          title="Villes à vérifier"
          columns={columns}
          data={datas}
          editable={{
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                updateDeleted(oldData);
                let rows = datas;
                setDatas(rows.filter((data) => data !== oldData));
                resolve();
              }),
          }}
          options={{
            exportButton: true,
            filtering: true,
            pageSize: 50,
            pageSizeOptions: [50, 100, 200],
          }}
        />
      )}
    </>
  );
}
