import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import GeoportailDatas from "../GeoportailDatas";
import GeoportailPushs from "../GeoportailPushs";
import NotreTerritoire from "../NotreTerritoire";
import Report from "../Report";
import PLUi from "../PLUi";
import CityToCheck from "../CityToCheck";
import PLU from "../PLUList";
import TabPanel from "./TabPanel";
import logo from "../../images/logoUrba.png";

import a11yProps from "../../utils/a11yProps";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Navigation() {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Box flexDirection="row" display="flex">
          <img src={logo} alt="logo" width="50px" />
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Geoportail Pushs" {...a11yProps(0)} />
            <Tab label="Geoportail Datas" {...a11yProps(1)} />
            <Tab label="PLUi" {...a11yProps(2)} />
            <Tab label="Notre Territoire" {...a11yProps(3)} />
            <Tab label="Rapports d'intégration" {...a11yProps(4)} />
            <Tab label="Villes à vérifier" {...a11yProps(5)} />
            <Tab label="PLU / PLUi Zones List" {...a11yProps(6)} />
          </Tabs>
        </Box>
      </AppBar>
      <TabPanel value={value} index={0}>
        <GeoportailPushs />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GeoportailDatas />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PLUi />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <NotreTerritoire />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Report />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <CityToCheck />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <PLU />
      </TabPanel>
    </div>
  );
}
